<template>
  <div class="login">
    <div class="container">
      <h3>博泉管理后台</h3>
      <el-input id="username" v-model="username" placeholder="用户名"></el-input>
      <el-input id="password" v-model="password" placeholder="密码" show-password></el-input>
      <el-button type="primary" @click="submit()">登陆</el-button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { mapGetters } from "vuex";
import { login, loginUC, sendUCCode } from "@/api/login";
import { Message, MessageBox } from "element-ui";
import config from "@/configs/index";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  computed: {
    sendUCCodeParams() {
      return {
        account: this.username,
        mobile_prefix: "+86",
        code_type: "signin",
      };
    },
    loginUcParams() {
      return {
        client_id: config.uc_client_id,
        provider: "local",
        account: this.username,
        password: this.password,
        mobile_prefix: "+86",
      };
    },
    loginAdminParams() {
      return { username: this.username, password: this.password };
    },
  },
  methods: {
    async submit() {
      // 同时调用 uc 和 admin 的登录接口
      // const [ucLogged, adminLogged] = await Promise.all([
      this.loginUserCenter().then(() => {
        if (localStorage.token) {
          const search = new URLSearchParams(window.location.search);
          const redirect = search.get("redirect");

          if (redirect) {
            router.replace(redirect);
          } else {
            router.replace({ path: "/" });
          }
        }
      });
      //   this.loginAdmin(),
      // ]);
      // // 同时成功才进入首页
      // // 先取消必须 uc login
      // if (adminLogged) {
      //   Message.success({
      //     message: "登陆成功",
      //     duration: 2000,
      //   });

      // } else {
      //   Message.error({
      //     message: "登录失败，请检查账号密码",
      //     duration: 2000,
      //   });
      // }
    },
    // 登录 uc
    async loginUserCenter(smsCode) {
      try {
        let params = { ...this.loginUcParams };
        if (smsCode) {
          params.code = smsCode;
        }
        const { data } = await loginUC(params);
        if (data && data.data) {
          const { token_type, access_token, refresh_token } = data.data;
          localStorage.setItem("token", `${token_type} ${access_token}`);
          // 设置 access_token refresh_token 到缓存
          localStorage.setItem("refresh_token", refresh_token);
        }

        return true;
      } catch (error) {
        const code = error?.data?.code;
        if (+code === 64231) {
          await sendUCCode(this.sendUCCodeParams);
          const { value } = await MessageBox.prompt("请输入验证码", "提示");
          return this.loginUserCenter(value);
        } else if (+code === 64221) {
          const { value } = await MessageBox.prompt("验证码错误，请输入验证码", "提示");
          return this.loginUserCenter(value);
        } else {
          return false;
        }
      }
    },
    // 登录原管理后台
    async loginAdmin() {
      try {
        const { data } = await login(this.loginAdminParams);
        // 通过 code === 0 判断是否登录成功
        const code = data && data.code;
        if (+code === 0) {
          // 设置 token 到缓存
          localStorage.setItem("token", data.data.token);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: lightblue;
  .container {
    width: 448px;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    h3 {
      margin-bottom: 30px;
      text-align: center;
      border-bottom: 1px solid #ccc;
    }
    .el-input {
      width: 300px;
      margin: 0 auto 20px;
      text-align: center;
    }
    button {
      width: 300px;
      font-size: 20px;
      margin: 0 auto 40px;
    }
  }
}
</style>
