import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet/api/admin";

// 登陆
export function login(data) {
  return axios({
    url: PRE_URL + "/login",
    method: "post",
    data,
  });
}

// 登录UC
export function loginUC(data) {
  return axios({
    url: config.openHost + "/v1/user/signin",
    method: "post",
    data,
  });
}

// 发生UC验证码
export function sendUCCode(data) {
  return axios({
    url: config.openHost + "/v1/codes",
    method: "post",
    data,
  });
}

// 获取管理员信息
export function getAdminInfo(data) {
  return axios({
    url: PRE_URL + "/info",
    method: "get",
    data,
  });
}

// 获取菜单信息
export function getMenu(data) {
  return axios({
    url: PRE_URL + "/menu",
    method: "get",
    data,
  });
}
